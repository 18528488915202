.contact {
  position: relative;

  form {
    display: grid;
    justify-content: stretch;
    grid-auto-flow: column;
    flex-wrap: wrap;
    border-top-radius: 2px;
    gap: 1px;
  }

  input {
    display: none;
  }

  label {
    padding: 10px 6px;
    flex: 1 1 auto;
    font-size: 0.8em;
    text-align: center;
    transition: none;
    background: var(--light-bg);

    @media (min-width: 375px) {
      font-size: 1em;
    }

    @media (min-width: 420px) {
      flex: 1;
    }

    > * {
      display: none;
      border-radius: 2px;
      position: absolute 100% 0 * 0;
      padding: 20px 14px;
      color: var(--bg) var(--text);
      text-align: initial;
    }
  }

  input:checked + label {
    border-top-radius: 2px;
    color: var(--bg) var(--text);

    > * {
      margin-top: -2px;
      display: initial;
    }
  }
}
