[data-aos='reveal-image'] {
  clip-path: circle(0% at 110px 95px);

  &.aos-animate {
    clip-path: circle(50% at center center);
  }
}

[data-aos='scale-center-x'] {
  transform-origin: center;
  transform: scale(0, 1);

  &.aos-animate {
    transform: scale(1, 1);
  }
}
