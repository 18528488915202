.tags {
  margin: 0;

  > * {
    background-color: var(--text);
    color: var(--bg);
    transition: var(--update_speed) ease;
    transition-property: background-color, color;
    margin: 0 4px 16px;
    padding: 4px 10px;
    display: block;

    @media (min-width: 420px) {
      display: inline-block;
    }

    &.special {
      background-color: var(--accent);
      color: var(--text);
      font-style: italic;
      font-weight: 500;
    }
  }
}

.tags--special > * {
  box-shadow: 4px 4px 0 var(--accent);
}

.tags--light > * {
  background-color: var(--light-bg);
  color: var(--text);
}
