.header {
  padding: 0 10px;

  h1 :first-child {
    transition: border-color var(--update_speed) ease;
    font-size: 2.8rem;
    font-weight: 100;

    @media (min-width: 420px) {
      font-size: 3.2rem;
    }
  }

  h1 :last-child {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 1.2px;
    margin: 10px 0 40px;

    @media (min-width: 420px) {
      margin-bottom: 80px;
    }
  }

  img {
    display: block;
    border-radius: 50%;
    margin: 0 auto;

    @media (min-width: 420px) {
      width: 50%;
    }
  }
}
