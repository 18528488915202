@keyframes show {
  15% {
    transform: rotate(-90deg);
    opacity: 0;
  }

  25% {
    transform: rotate(0);
    opacity: 1;
  }

  90% {
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform: rotate(-90deg);
    opacity: 0;
  }
}

.colors {
  display: flex;
  align-items: baseline;

  position: fixed 20px 20px * *;
  font-size: 0.7em;

  &.hint::before {
    animation: show 5s ease;
  }

  &::before {
    content: attr(data-text);
    white-space: nowrap;
    position: absolute * calc(100% + 10px) * *;
    transform-origin: calc(100% + 20px) center;
    transform: rotate(-90deg);
    opacity: 0;
    transition: transform 0.2s ease, opacity 0.2s ease;
    z-index: -1;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover::before {
      transform: rotate(0);
      opacity: 1;
    }
  }

  button {
    display: flex;
    padding: 10px;
    width: 26px;
    height: 26px;
    background: var(--light-bg);
    border: none;
    border-radius: 4px;
    align-items: center;

    &.play {
      &::after {
        content: '';
        border: 4px solid transparent;
        border-left: 5px solid var(--text);
        background: none;
        width: 0;
        height: 0;
      }
    }

    &.pause {
      justify-content: space-between;

      &::before,
      &::after {
        content: '';
        width: 2px;
        height: 8px;
        background: var(--text);
      }
    }
  }
}
