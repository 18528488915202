* {
  box-sizing: border-box;
}

body {
  display: grid;
  gap: 100px;
  margin: 20px auto 150px;
  max-width: 600px;
  padding: 20px;
  background-color: var(--bg);
  transition: background var(--update_speed) ease;
  color: var(--text);
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.5;

  @media (min-width: 420px) {
    margin-top: 50px;
    gap: 8vw;
  }

  @media (min-width: 2000px) {
    gap: 200px;
  }
}

hr {
  border: none;
  height: 0;
  border-bottom: 2px solid var(--accent);
}

h1 {
  margin: 0;
  text-align: center;
  font-size: 1.9rem;
}

h2 {
  font-size: 1.66666rem;
  margin: 0 0 40px;
}

h3 {
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 500;
  margin: 40px 0 20px;
}

h3 {
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 500;
  margin: 10px 0 20px;
}

ul {
  padding: 0;
}

a,
a:any-link {
  padding-bottom: 3px;
  border-bottom: 2px solid var(--accent);
  color: inherit;
  text-decoration: none;

  transition: border-color var(--update_speed) ease;
}

img {
  width: 100%;
}
