/* raleway-100 - latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 100;
  src: local("Raleway Thin"), local("Raleway-Thin"),
    url("/fonts/raleway-v13-latin-100.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("/fonts/raleway-v13-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* raleway-300 - latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  src: local("Raleway Light"), local("Raleway-Light"),
    url("/fonts/raleway-v13-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("/fonts/raleway-v13-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* raleway-500italic - latin */
@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 500;
  src: local("Raleway Medium Italic"), local("Raleway-MediumItalic"),
    url("/fonts/raleway-v13-latin-500italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("/fonts/raleway-v13-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
